import React, { lazy, Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Navbar from './components/Navbar';
import Footer from './components/footer';
import Loading from './components/Loading';

// Lazy load pages
const Home = lazy(() => import('./pages/Home'));
const About = lazy(() => import('./pages/About'));
const Pricing = lazy(() => import('./pages/Pricing'));
const Gallery = lazy(() => import('./pages/Gallery'));
const PhotoSessionPage = lazy(() => import('./pages/PhotoSessionPage'));
const SportsSessionPage = lazy(() => import('./pages/SportsSessionPage'));
const NotFound = lazy(() => import('./pages/NotFound'));

function App() {
  return (
    <Router>
      <div className="App">
        <Navbar />
        <div className="content">
          <Suspense fallback={<Loading />}>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/about" component={About} />
              <Route path="/pricing" component={Pricing} />
              <Route exact path="/gallery" component={Gallery} />
              <Route path="/gallery/:category" component={Gallery} />
              <Route path="/photoSessionPage" component={PhotoSessionPage} />
              <Route path="/sportsSessionPage" component={SportsSessionPage} />
              <Route path="*" component={NotFound} />
            </Switch>
          </Suspense>
        </div>
        <Footer />
      </div>
    </Router>
  );
}

export default App;
