import { Link, useLocation } from 'react-router-dom';

const Navbar = () => {
  const location = useLocation();
  const isHomePage = location.pathname === '/';

  return (
    <nav className='absolute flex top-0 left-0 w-full z-20 opacity-80 overflow-x-hidden'>
      <div className='flex flex-col justify-center items-center w-full'>
        <div className='flex justify-between items-center w-full h-full text-sm sm:text-xl py-2 sm:py-4 px-4'>
          <div className='flex justify-center items-center w-full'>
            <Link
              to='/'
              className='text-black no-underline text-center px-2 sm:px-5 py-1 sm:py-2 font-bold opacity-80 hover:opacity-100'
            >
              Home
            </Link>
            <Link
              to='/about'
              className='text-black no-underline text-center px-2 sm:px-5 py-1 sm:py-2 font-bold opacity-80 hover:opacity-100'
            >
              About
            </Link>
            <Link
              to='/pricing'
              className='text-black no-underline text-center px-2 sm:px-5 py-1 sm:py-2 font-bold opacity-80 hover:opacity-100'
            >
              Pricing
            </Link>
            <Link
              to='/gallery'
              className='text-black no-underline text-center px-2 sm:px-5 py-1 sm:py-2 font-bold opacity-80 hover:opacity-100'
            >
              Gallery
            </Link>
          </div>
        </div>
        {!isHomePage && (
          <div className='flex-auto justify-center items-center text-center text-black font-bold text-lg sm:text-xl'>
            Macaulay Takes Pictures
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
